<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <!--Transaction Summary-->
        <v-alert color="grey lighten-3">
          <v-container>
            <!--Transaction Amount-->
            <v-row dense>
              <v-col lg="3" md="3" sm="3">Transaction Amount</v-col>
              <v-col lg="3" md="3" sm="3">
                <span class="float-right">{{
                  formatAsCurrency("R", this.transactionAmount)
                }}</span>
              </v-col>
            </v-row>
            <!--Amount Allocated-->
            <v-row dense>
              <v-col lg="3" md="3" sm="3">Amount Allocated</v-col>
              <v-col lg="3" md="3" sm="3">
                <span class="float-right">{{
                  formatAsCurrency("R", Math.abs(amountAllocated))
                }}</span>
              </v-col>
            </v-row>
            <!--Amount Left To Allocate-->
            <v-row dense>
              <v-col lg="3" md="3" sm="3">Amount Left To Allocate</v-col>
              <v-col lg="3" md="3" sm="3">
                <span class="float-right">{{
                  formatAsCurrency("R", amountLeftToAllocate)
                }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          v-model="selected_credit_notes"
          :items="filteredCreditNotes"
          :headers="credit_note_headers"
          sort-by="credit_note_number"
          :items-per-page="1000"
          show-select
          item-key="credit_note_number"
          hide-default-footer
        >
          <!--Selection checkbox-->
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :ripple="false"
              @input="select($event)"
              :readonly="amountLeftToAllocate <= 0 && !isSelected"
              :disabled="amountLeftToAllocate <= 0 && !isSelected"
            ></v-simple-checkbox>
          </template>
          <!--Format amounts-->
          <template v-slot:item.total_credited_amount="{ item }">
            {{ formatAsCurrency("R", item.total_credited_amount) }}
          </template>
          <template v-slot:item.credit_note_amount_refunded="{ item }">
            {{ formatAsCurrency("R", item.credit_note_amount_refunded) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--Allocate Transaction-->
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="float-right"
          :disabled="disabled"
          :loading="loading"
          @click="allocateRefund()"
        >
          <v-icon small left>mdi-check</v-icon>
          Allocate Refund</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import firebase from "firebase";
import { formatAsCurrency } from "../../../../../composables/external";
import { showSnackbar } from "../../../../../globalActions";
export default {
  name: "CreditNoteRefundsForm",
  props: ["transaction", "customer", "unfiltered_credit_notes"],
  data() {
    return {
      selected_credit_notes: [],
      credit_note_headers: [
        { text: "Credit Note #", value: "credit_note_number", align: "start" },
        { text: "Customer", value: "customer_name" },
        { text: "Reference", value: "reference_number" },
        { text: "Date Issued", value: "credit_note_date" },
        { text: "Credited Amount", value: "total_credited_amount" },
        { text: "Amount Refunded", value: "credit_note_amount_refunded" },
      ],
      journal_entry_number: null,
      loading: false,
    };
  },
  computed: {
    transactionAmount() {
      return Math.abs(this.transaction.transaction_amount);
    },
    filteredCreditNotes() {
      return this.unfiltered_credit_notes.filter(
        (x) =>
          x.customer.customer_name === this.customer &&
          x.total_credited_amount !== x.old_amount_refunded
      );
    },
    amountAllocated() {
      return this.selected_credit_notes.reduce((total, item) => {
        return total + item.allocatedPortion;
      }, 0);
    },
    amountLeftToAllocate() {
      return (
        this.transactionAmount -
        this.selected_credit_notes.reduce(
          (total, item) => total + item.allocatedPortion,
          0
        )
      );
    },
    disabled() {
      if (this.amountLeftToAllocate !== 0) return true;
      else return false;
    },
  },
  watch: {
    selected_credit_notes(newValue, oldValue) {
      if (newValue.length === 0) return;
      // if items are added to selection
      if (newValue.length > oldValue.length) {
        // Set refunded amount on item
        const newSelectedItem = newValue.filter(
          (x) => !oldValue.includes(x)
        )[0];
        // update credit note array item refunded amount
        const index = this.filteredCreditNotes.findIndex(
          (item) => item.credit_note_id === newSelectedItem.credit_note_id
        );
        // Calculate amount to allocate
        const refundAmountDue =
          newSelectedItem.total_credited_amount -
          newSelectedItem.old_amount_refunded;
        // If the transaction amount is less than the amount due on the refund
        // Use the transaction total
        // Else use the amount due on the refund
        const calcValue =
          this.transactionAmount <= refundAmountDue
            ? this.transactionAmount
            : refundAmountDue;

        this.filteredCreditNotes[index].allocatedPortion = calcValue;
        this.filteredCreditNotes[index].credit_note_amount_refunded +=
          calcValue;
        return;
      }
      // if items are removed from selection
      else {
        const removedItem = oldValue.filter((x) => !newValue.includes(x))[0];
        // update credit note array item refunded amount
        const index = this.filteredCreditNotes.findIndex(
          (item) => item.credit_note_id === removedItem.credit_note_id
        );
        this.filteredCreditNotes[index].credit_note_amount_refunded =
          removedItem.old_amount_refunded;
        return;
      }
    },
    customer(newValue, oldValue) {
      if (newValue === oldValue) return;
      // Reset allocated portions on all credit notes
      const previousCreditNotes = this.unfiltered_credit_notes.filter(
        (x) => x.customer.customer_name === oldValue
      );
      previousCreditNotes.forEach((item) => {
        return (item.credit_note_amount_refunded = item.old_amount_refunded);
      });
    },
  },
  created() {
    // Get and format GJE number
    (async () => {
      const snapshot = await db
        .doc("general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2")
        .get();
      const count = snapshot.data().journal_entry_count;
      return (this.journal_entry_number = `GJE-${
        count < 1000 ? String("00000" + count).slice(-4) : count
      }`);
    })();
  },
  methods: {
    formatAsCurrency,
    resetSelected() {
      // Clear selected credit notes if customer changes
      this.selected_credit_notes = [];
    },
    //
    async allocateRefund() {
      // Validation
      if (this.selected_credit_notes.length === 0) return;
      if (this.amountAllocated > this.transactionAmount) return;
      this.loading = true;
      const batch = db.batch();
      const entryRef = db.collection("general_journal_entries").doc();
      const lineItems = [];
      // 1) CREATE GJE
      const createJournalEntry = () => {
        // Increase "Suspense" Contra
        const suspenseContra = {
          entry_amount: this.transactionAmount,
          line_amount: this.transactionAmount,
          ledger_account: {
            account_id: "GHxChc2AGkXMiQnSvqNC",
            account_name: "Suspense Contra (Bank Credits)",
          },
        };
        // Increase 'Credit Note Refunds' ledger
        const creditNotesOnly = this.selected_credit_notes.filter(
          (x) => !!x.invoice
        );
        const entryTotalAmount = creditNotesOnly.reduce((total, item) => {
          return total + item.allocatedPortion;
        }, 0);

        const creditNoteRefunds = {
          entry_amount: entryTotalAmount,
          line_amount: entryTotalAmount,
          ledger_account: {
            account_id: "c6GJuC931BF9MvQ5HTOS",
            account_name: "Credit Note Refunds",
          },
        };
        lineItems.push(suspenseContra);
        /*
            If only downpayment refunds have been selected, don't increase
            'Credit Note Refunds' ledger
        */
        if (entryTotalAmount > 0) lineItems.push(creditNoteRefunds);
        //
        const creditNoteDetails = this.selected_credit_notes.map((x) => {
          return {
            credit_note_id: x.credit_note_id,
            credit_note_number: x.credit_note_number,
            total_credited_amount: x.total_credited_amount,
            allocated_amount: x.allocatedPortion,
          };
        });
        batch.set(entryRef, {
          journal_entry_id: entryRef.id,
          journal_entry_date: this.transaction.transaction_date,
          journal_entry_number: this.journal_entry_number,
          closed_off: false,
          //  Add credit note details so that GJE can be deleted if credit notes are deleted
          credit_notes: creditNoteDetails,
          bank_transaction: {
            transaction_id: this.transaction.transaction_id,
            transaction_number: this.transaction.transaction_number,
          },
          line_items: lineItems,
        });
      };
      createJournalEntry();
      // 2) Update accounting_totals ledgers
      const updateLedgers = () => {
        lineItems.forEach((item) => {
          const docRef = db.doc(
            `accounting_totals/${this.transaction.transaction_date.slice(
              0,
              7
            )}/ledger_accounts/${item.ledger_account.account_id}`
          );
          batch.update(docRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              item.entry_amount
            ),
          });
        });
      };
      updateLedgers();
      /*
        3) Update 'amount refunded' on selected credit notes, and link
        bank transaction and journal entry info
      */
      const updateCreditNotes = () => {
        this.selected_credit_notes.forEach((item) => {
          const docRef = db.doc(`customer_credit_notes/${item.credit_note_id}`);
          batch.update(docRef, {
            credit_note_amount_refunded: item.credit_note_amount_refunded,
            transaction_id: this.transaction.transaction_id,
            transaction_number: this.transaction.transaction_number,
            entry_id: entryRef.id,
          });
        });
      };
      updateCreditNotes();
      // 4) Update bank transaction
      const updateBankTransaction = () => {
        const bankRef = db.doc(
          `bank_transactions/${this.transaction.transaction_id}`
        );
        batch.update(bankRef, {
          transaction_allocation_status: "Credit Note Refund",
          journal_entry: {
            journal_entry_id: entryRef.id,
            journal_entry_number: this.journal_entry_number,
          },
        });
      };
      updateBankTransaction();
      // Increase GJE number
      const GJEcountRef = db.doc(
        "general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2"
      );
      batch.update(GJEcountRef, {
        journal_entry_count: firebase.firestore.FieldValue.increment(1),
      });
      await batch.commit();
      showSnackbar("Credit Note Refund allocated successfully!");
      this.loading = false;
      this.$emit("passModalClose", false);
    },
  },
};
</script>